import { ReactElement } from 'react';
import { TrustedByStyle } from '../../../styles/components/common/blocks';

export interface ITrustedByProps {
  title: string;
  companies: ITrustedByCompany[];
}

export interface ITrustedByCompany {
  company: string;
  image: ReactElement;
}

const TrustedBy = (props: ITrustedByProps) => {
  return (
    <div className={TrustedByStyle.trustedBy}>
      <h2>{props.title}</h2>
      <div className={TrustedByStyle.companiesList}>
        {props.companies
          ? (props.companies as ITrustedByCompany[]).map(
              (data: any, index: number) => {
                return <Company key={index} {...data} />;
              }
            )
          : ''}
      </div>
    </div>
  );
};

const Company = ({ image }: ITrustedByCompany) => {
  return <div className={TrustedByStyle.company}>{image}</div>;
};

export default TrustedBy;
